/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements. Licensed under a proprietary license.
 * See the License.txt file for more information. You may not use this file
 * except in compliance with the proprietary license.
 */

import {Tooltip} from '@carbon/react';
import {CheckmarkFilled} from '@carbon/icons-react';

import './Checkmark.scss';

interface CheckmarkProps {
  event: {
    eventLabel?: string;
    eventName: string;
    source: string;
  };
}

export default function Checkmark({event: {eventLabel, eventName, source}}: CheckmarkProps) {
  return (
    <div className="Checkmark">
      <Tooltip
        align="top"
        autoAlign
        label={
          <div className="tooltipContent">
            <span>{eventLabel || eventName}</span>
            <br /> source: {source}
          </div>
        }
      >
        <CheckmarkFilled size="18" />
      </Tooltip>
    </div>
  );
}
