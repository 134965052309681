/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements. Licensed under a proprietary license.
 * See the License.txt file for more information. You may not use this file
 * except in compliance with the proprietary license.
 */

import * as processOptions from './process';
import * as decisionOptions from './decision';
export {createReportUpdate, getDefaultSorting} from './reportConfig';

const config = {
  process: processOptions,
  decision: decisionOptions,
};

export default config;
