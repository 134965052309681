/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements. Licensed under a proprietary license.
 * See the License.txt file for more information. You may not use this file
 * except in compliance with the proprietary license.
 */

import {ComponentProps} from 'react';
import {Link} from 'react-router-dom';
import {MenuButton, MenuItem} from '@carbon/react';

import {t} from 'translation';
import {useUiConfig} from 'hooks';

interface CreateNewButtonProps
  extends Pick<ComponentProps<typeof MenuButton>, 'kind' | 'size' | 'tabIndex' | 'disabled'> {
  create: (type: 'report' | 'dashboard' | 'kpi' | 'collection') => void;
  collection?: string;
  importEntity: () => void;
}

export default function CreateNewButton({
  create,
  collection,
  importEntity,
  kind = 'tertiary',
  size = 'md',
  disabled,
  tabIndex,
}: CreateNewButtonProps): JSX.Element {
  const {optimizeDatabase, optimizeProfile} = useUiConfig();

  return (
    <MenuButton
      size={size}
      kind={kind}
      label={t('home.createBtn.default').toString()}
      className="CreateNewButton"
      disabled={disabled}
      tabIndex={tabIndex}
    >
      {!collection && (
        <MenuItem
          onClick={() => create('collection')}
          label={t('home.createBtn.collection').toString()}
        />
      )}
      {optimizeDatabase !== 'opensearch' ? (
        <MenuItem
          onClick={() => create('dashboard')}
          label={t('home.createBtn.dashboard').toString()}
        />
      ) : (
        <Link to="dashboard/new/edit">
          <MenuItem label={t('home.createBtn.dashboard').toString()} />
        </Link>
      )}
      {optimizeProfile === 'platform' ? (
        <MenuItem label={t('home.createBtn.report.default').toString()}>
          <MenuItem
            onClick={() => create('report')}
            label={t('home.createBtn.report.process').toString()}
          />
          {optimizeDatabase !== 'opensearch' && (
            <>
              <MenuItem
                onClick={() => create('kpi')}
                label={t('report.kpiTemplates.processKpi').toString()}
              />
              <Link to="report/new-combined/edit">
                <MenuItem label={t('home.createBtn.report.combined').toString()} />
              </Link>
            </>
          )}
          <Link to="report/new-decision/edit">
            <MenuItem label={t('home.createBtn.report.decision').toString()} />
          </Link>
        </MenuItem>
      ) : (
        <>
          <MenuItem
            onClick={() => create('report')}
            label={t('home.createBtn.report.default').toString()}
          />
          {optimizeDatabase !== 'opensearch' && (
            <MenuItem
              onClick={() => create('kpi')}
              label={t('report.kpiTemplates.processKpi').toString()}
            />
          )}
        </>
      )}
      <MenuItem onClick={importEntity} label={t('common.importReportDashboard').toString()} />
    </MenuButton>
  );
}
