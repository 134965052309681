/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements. Licensed under a proprietary license.
 * See the License.txt file for more information. You may not use this file
 * except in compliance with the proprietary license.
 */

import {useCallback, useState} from 'react';
import deepEqual from 'fast-deep-equal';

export default function useChangedState<T>(initialState: T) {
  const [state, _setState] = useState<T>(initialState);

  const setState = useCallback((newState: T) => {
    _setState((prevState) => (deepEqual(prevState, newState) ? prevState : newState));
  }, []);

  return [state, setState];
}
